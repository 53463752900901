<template>
  <div>
    <LogInBase />
  </div>
</template>
<script>
import LogInBase from "~/components/login/LogInBase.vue";
export default {
  data: function () {
    return {
      title: "Macher Log In"
    };
  },
  components: { LogInBase }
};
</script>
